import { ReactComponent as InfoCircle } from 'asset/info-circle.svg';
import { Tooltip, IconButton } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

const CustomIconButton = styled(IconButton)(({ iconBorderColor }) => ({
  color: iconBorderColor,
}));

export default function CustomInfoIcon({ title, svgProps, iconBtnStyles, iconBorderColor,
  placement }) {
  return (
    <Tooltip title={title} placement={placement || 'top'}>
      <CustomIconButton aria-label="info" iconBorderColor={iconBorderColor || 'secondary'} size="small" style={iconBtnStyles}>
        <InfoCircle fontSize="inherit" {...svgProps} />
      </CustomIconButton>
    </Tooltip>
  );
}

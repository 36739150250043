import React, { useRef, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import axios from 'axios';
import { Add, DeleteForever, List } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import LockIcon from '@mui/icons-material/Lock';
import ShareIcon from '@mui/icons-material/Share';
import { useSelector } from 'react-redux';
import { MAIN_HOST } from 'common/AppConstant';
import * as Constants from 'common/Constants';
import store from 'app/store';
import { FORMAT_NATURAL, convertTimestamp } from 'common/util/commonUtil';
import { confirmDialog } from 'common/components/ConfirmationDialog';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import FileUploadDialog from 'common/components/FileUploadDialog';
import { saveWeightsFile } from 'features/evaluation/state/service';
import { getMenuCounts } from 'features/count/state/service';
import StudiesListDialog from 'features/evaluation/component/studiesListDialog';
import WeightDetailDialog from './weightDetailDialog';
import { checkWeightsLinkage, deleteWeightsById, toggleWeightSetPrivacy } from './state/service';

export default function Weights() {
  const tableRef = useRef();

  const [data, setData] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addWeightsDialogOpen, setWeightsDialogOpen] = useState(false);
  const [studiesListId, setStudiesListId] = useState(null);
  const refreshFlag = useSelector((state) => state.weights.refreshFlag);
  const userId = useSelector((state) => state.auth.userId);

  const handleRowClick = (rowData) => {
    setData(rowData);
    setDialogOpen(true);
  };

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };
  const updateWeightCount = () => {
    const fetchCriteria = {
      fetchEvaluationCount: false,
      fetchSearchCount: false,
      fetchWeightsCount: true,
    };
    store.dispatch(getMenuCounts(fetchCriteria));
  };

  const handleWeightsDelete = (id, name) => {
    store
      .dispatch(checkWeightsLinkage(id))
      .unwrap()
      .then((response) => {
        if (response === true) {
          const message = 'You cannot delete this custom weight set because it is being used in an Intelligence Study.';
          confirmDialog(true, message, 'Delete Not Allowed');
        } else {
          const message = `Are you sure you want delete custom weight set with name "${name}"?`;
          confirmDialog(() => store.dispatch(deleteWeightsById(id)), message);
        }
      });
  };

  const handleListStudies = (id) => {
    setStudiesListId(id);
  };

  const handleWeightSetPrivacy = (isPrivate, id) => { // POPTX-361
    let message = 'Are you sure you want to make this custom weight set private?';
    if (isPrivate) {
      message = 'Are you sure you want to share this custom weight set?';
    }
    confirmDialog(() => store.dispatch(toggleWeightSetPrivacy(id)), message);
  };

  useEffect(() => {
    refresh();
    updateWeightCount();
  }, [refreshFlag]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: '30%',
      cellStyle: { width: '30%' },
      render: (rowData) => (
        <button
          id={`custom_weight_name_${rowData.tableData.index}`}
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            outlineColor: Constants.PRIMARY,
            cursor: 'pointer',
            display: 'inline',
            margin: 0,
            padding: 0,
            fontSize: '1rem',
            overflowWrap: 'anywhere',
            textAlign: 'left',
            whiteSpace: 'normal',
            color: Constants.PRIMARY,
          }}
          onClick={() => {
            handleRowClick(rowData);
          }}
        >
          {rowData.name}
        </button>
      ),
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
      width: '24%',
      cellStyle: { width: '24%' },
      render: (rowData) => (
        <span id={`custom_weight_updatedAt_${rowData.tableData.index}`}>
          {convertTimestamp(rowData.updatedAt)}
        </span>
      ),
    },
    {
      title: 'Weight Count',
      field: 'weightCount',
      width: '2%',
      cellStyle: { width: '2%' },
      render: (rowData) => (
        <span id={`custom_weight_weightCount_${rowData.tableData.index}`}>
          {FORMAT_NATURAL(rowData.weightCount)}
        </span>
      ),
    },
    {
      title: 'Studies',
      field: 'noOfStudies',
      width: '1%',
      cellStyle: { width: '1%' },
      render: (rowData) => (
        <span id={`custom_weight_studies_${rowData.tableData.index}`}>
          {FORMAT_NATURAL(rowData.noOfStudies)}
        </span>
      ),
    },
    {
      title: 'Created By',
      field: 'createdUserName',
      width: '22%',
      cellStyle: {
        width: '22%',
      },
      render: (rowData) => (
        <span id={`custom_weight_createdBy_${rowData.tableData.index}`}>
          {rowData.createdUserName}
        </span>
      ),
    },
    {
      title: 'Visibility',
      field: 'privateWeights',
      width: '1%',
      cellStyle: { width: '1%' },
      render: (rowData) => (
        <span id={`custom_weight_visibility_${rowData.tableData.index}`}>
          {rowData.privateWeights
            ? <Typography>Private</Typography> : <Typography>Shared</Typography>}
        </span>
      ),
    },
  ];

  return (
    <>
      <WeightDetailDialog data={data} handleClose={() => setDialogOpen(false)} open={dialogOpen} />
      <CustomizedMaterialTable
        title="Custom Weights"
        tableRef={tableRef}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
        columns={columns}
        data={(query) =>
          new Promise((resolve) => {
            axios
              .post(`${MAIN_HOST}/weights/view/all`, {
                page: query.page,
                size: query.pageSize,
                searchTerm: query.search,
                sortProperty:
                  Array.isArray(query.orderByCollection) && query.orderByCollection.length === 1
                    ? columns[query.orderByCollection[0].orderBy].field
                    : 'name',
                direction:
                  Array.isArray(query.orderByCollection)
                    && query.orderByCollection.length === 1
                    && query.orderByCollection[0].orderDirection
                    ? query.orderByCollection[0].orderDirection.toUpperCase()
                    : Constants.DIRECTION_DESC,
              })
              .then((m) => {
                const resData = m.data;
                resolve({
                  data: resData.content,
                  page: resData.number,
                  size: resData.size,
                  totalCount: resData.totalElements,
                });
              })
              .catch(() => {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              });
          })
        }
        actions={[
          {
            icon: () => <Add />,
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => setWeightsDialogOpen(true),
          },
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => {
              refresh();
            },
          },

          (rowInfo) => ({
            icon: () => <List />,
            tooltip: 'List Studies',
            disabled: !rowInfo.noOfStudies,
            onClick: (_, rowData) => {
              handleListStudies(rowData?.id);
            },
          }),
          (rowInfo) => ({
            icon: () => <DeleteForever id="delete_custom_weights" />,
            tooltip: 'Delete',
            disabled: (!!rowInfo.noOfStudies) || rowInfo.createdBy !== userId,
            onClick: (_, rowData) => {
              handleWeightsDelete(rowData?.id, rowData?.name);
            },
          }),
          (rowInfo) => ({
            icon: () => <LockIcon />,
            tooltip: 'Make Private',
            hidden: rowInfo.privateWeights,
            disabled: rowInfo.createdBy === userId
              ? (rowInfo.noOfStudies !== rowInfo.noOfOwnerStudies) : true,
            onClick: () => handleWeightSetPrivacy(rowInfo.privateWeights, rowInfo.id),
          }),
          (rowInfo) => ({
            icon: () => <ShareIcon />,
            tooltip: 'Share',
            hidden: rowInfo.createdBy !== userId || rowInfo.privateWeights === false,
            onClick: () => handleWeightSetPrivacy(rowInfo.privateWeights, rowInfo.id),
          }),
        ]}
      />
      <FileUploadDialog
        open={addWeightsDialogOpen}
        onClose={() => {
          setWeightsDialogOpen(false);
        }}
        dialogDisplayInfo={Constants.FILE_UPLOAD_PROPS.customWeights}
        saveFileReduxFunction={saveWeightsFile}
      />
      {studiesListId && (
        <StudiesListDialog
          id={studiesListId}
          handleClose={() => {
            setStudiesListId(null);
          }}
          viewName="weights"
        />
      )}
    </>
  );
}

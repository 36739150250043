import React from 'react';
import RoutePath from 'app/router/RoutePath';
import { useNavigate } from 'react-router-dom';
import { List } from '@mui/material';
import SubMenuItem from './SubMenuItem';

const subMenuMapWithPermision = () => ({
  completed: {
    title: 'Release Notes',
    path: RoutePath.app.resources.subpage.releaseNotes.path,
    show: true, // should the menu be shown on UI?
  },
});

export default function ResourcesSubMenu({ sideBarOpen }) {
  const navigate = useNavigate();
  const subMenuMap = subMenuMapWithPermision();

  return (
    <List component="div" disablePadding>
      {
        Object.keys(subMenuMap).map((subMenu) => {
          if (subMenuMap[subMenu].show) {
            return (
              <SubMenuItem
                key={subMenu}
                title={subMenuMap[subMenu].title}
                clickHandler={() => navigate(subMenuMap[subMenu].path)
                }
                sideBarOpen={sideBarOpen}
              />
            );
          }
          return null;
        })}
    </List>
  );
}

import React, { forwardRef } from 'react';
import { Typography, Table, TableHead, TableRow, Tooltip } from '@mui/material';
import { EVALTYPE, TOOLTIP_MSG } from 'common/Constants';
import TableCellHeader from 'common/components/TableCellHeader';
import SortIndicator from 'common/components/SortIndicator';
import { PopStudyTableCell } from './PopulationStyles';

function PopulationHeaderColumns() {
  return (
    <>
      <PopStudyTableCell width="24%" align="center">
        <Tooltip key="populationCount" title={TOOLTIP_MSG().VIEW_POPULATION_STUDY.POPULATION_COUNT} placement="top">
          <Typography variant="paraHeader">Population Count</Typography>
        </Tooltip>
      </PopStudyTableCell>
      <PopStudyTableCell width="26%" align="center">
        <Tooltip key="populationPercent" title={TOOLTIP_MSG().VIEW_POPULATION_STUDY.POPULATION_PERCENTAGE} placement="top">
          <Typography variant="paraHeader">Population %</Typography>
        </Tooltip>
      </PopStudyTableCell>
    </>
  );
}

function StudyHeaderColumn({ order, orderBy, active, handleSort }) {
  return (
    <PopStudyTableCell width="50%" padding="none">
      <TableCellHeader
        key="weight"
        label="Weight"
        sortDirection={orderBy === 'weight' ? order : false}
        active={orderBy === 'weight' && active}
        direction={orderBy === 'weight' && active ? order : 'asc'}
        onClick={handleSort('weight')}
        IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} height="24px" />)}
        headerSX={{ color: 'black', fontWeight: '500', fontSize: '0.875rem' }}
        tableCellSX={{ float: 'right' }}
        toolTipTitle={TOOLTIP_MSG().VIEW_PROVIDER_OR_MARKET_STUDY.WEIGHT}
      />
    </PopStudyTableCell>
  );
}

export default function MsaResultsHeader({ evalType, order, orderBy, onRequestSort, active }) {
  const handleSort = (property) => (event) => {
    onRequestSort(property, event);
  };

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow sx={{ display: 'flex', alignItems: 'flex-end', verticalAlign: 'bottom !important', height: '40px' }}>
          <PopStudyTableCell width="50%" align="center">
            {evalType === EVALTYPE.POPULATION
              ? (
                <Typography variant="paraHeader">Name</Typography>
              ) : (
                <TableCellHeader
                  key="name"
                  label="Name"
                  sortDirection={orderBy === 'name' ? order : false}
                  active={orderBy === 'name' && active}
                  direction={orderBy === 'name' && active ? order : 'asc'}
                  onClick={handleSort('name')}
                  IconComponent={forwardRef((prop, ref) => <SortIndicator {...prop} ref={ref} height="24px" />)}
                  headerSX={{ color: 'black', fontWeight: '500', fontSize: '0.875rem' }}
                />
              )}
          </PopStudyTableCell>
          {evalType === EVALTYPE.POPULATION
            ? (
              <PopulationHeaderColumns />
            ) : (
              <StudyHeaderColumn
                order={order}
                orderBy={orderBy}
                active={active}
                handleSort={handleSort}
              />
            )}
        </TableRow>
      </TableHead>
    </Table>
  );
}

import { Grid } from '@mui/material';
import { HOME_PAGE_CARD_MAP } from 'common/Constants';
import React from 'react';
import { styled } from '@mui/system';
import { usePermissions } from 'features/common/hooks/usePermissions';
import HomeBanner from './components/HomeBanner';
import TopPlans from './components/TopPlans';
import NavigationCard from './components/NavigationCard';

const SectionCard = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}));

const LandingWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2rem',
  marginInline: 'auto',
}));

export default function Home() {
  const { hasPermission } = usePermissions();
  const displayCard = (permission) => {
    if (!permission) {
      return true;
    }
    return hasPermission(permission);
  };
  return (
    <>
      <Grid>
        <HomeBanner />
      </Grid>
      <LandingWrapper>
        <ContentWrapper>
          <TopPlans />
          <SectionCard>
            {HOME_PAGE_CARD_MAP.map((card) => (
              (card.show === true && displayCard(card.permission))
                && <NavigationCard key={card.key} detail={card} />
            ))}
          </SectionCard>
        </ContentWrapper>
      </LandingWrapper>
    </>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import { INACTIVITY_WARNING_TIME } from 'common/Constants';
import InactivityDialog from './InactivityDialog';

export default function InactivityHandler() {
  const [showInactiveDialog, setShowInactiveDialog] = useState(false);
  const popupTimeoutRef = useRef(null);

  const resetTimeout = () => {
    if (popupTimeoutRef.current) clearTimeout(popupTimeoutRef.current);

    popupTimeoutRef.current = setTimeout(() => {
      setShowInactiveDialog(true);
    }, INACTIVITY_WARNING_TIME); // 18 minutes
  };

  useEffect(() => {
    const handleActivity = () => {
      resetTimeout();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    resetTimeout();

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearTimeout(popupTimeoutRef?.current);
    };
  }, []);

  const handleDialogAction = () => {
    resetTimeout();
    setShowInactiveDialog(false);
  };

  return (
    <div id="inactivity-handler">
      {showInactiveDialog && <InactivityDialog handleOnClose={handleDialogAction} />}
    </div>
  );
}

/*
This component is to display count badge on Left side Navigation Menu,
to avoid code duplication.
*/
import * as React from 'react';
import { ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCountBadge = styled(ListItemText)(({ theme }) => ({
  backgroundColor: theme.palette.accent.shade200,
  borderRadius: '6px',
  textAlign: 'center',
  alignSelf: 'center',
  height: '80%',
  padding: '0px 4px',
}));

export default function CountBadge({ count }) {
  return (
    <StyledCountBadge
      primary={count}
      primaryTypographyProps={{ fontSize: '0.75rem', fontWeight: '500', minWidth: '20px' }}
     />
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import React from 'react';
import ClaimsDetail from './ClaimsDetail';

export default function ClaimsDetailDialog({ data, open, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogContent>
        <ClaimsDetail data={data} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import Spinner from 'common/components/spinner';
import ConfirmDialog from 'common/components/ConfirmationDialog';
import { updateSystemErrorMsg } from 'app/state/reducer';
import { refreshToken } from 'features/login/state/service';
import { SIGN_OUT, USE_APPD, USE_CLARITY } from 'common/AppConstant';
import AppDynamicsRUM from 'AppDynamicsRUM';
import InactivityHandler from 'features/inactivity/InactivityHandler';
import customTheme from 'customTheme';
import store from './app/store';
import AppRoute from './app/router/AppRoute';

export default function App() {
  const dispatch = useDispatch();

  const systemMsg = useSelector((state) => state.app.systemMsg);
  const jwtExp = useSelector((state) => state.auth.jwtExp);
  const isFetchingQueue = useSelector(
    (state) => state.app.isFetchingQueue,
  );

  const systemMsgSeverity = useSelector(
    (state) => state.app.systemMsgSeverity,
  );

  const onCloseError = () => {
    dispatch(updateSystemErrorMsg(null));
  };

  useEffect(() => {
    const timer = setInterval(
      () => {
        const signout = localStorage.getItem(SIGN_OUT);
        if (signout !== 'true' && jwtExp) {
          const leftTime = (jwtExp * 1000) - Date.now();
          // if left time > 0 then, the token is valid
          // if the token is expired in 5 mins, refresh the token
          if (leftTime > 0 && leftTime < 300000) {
            store.dispatch(refreshToken());
          }
        }
      },
      1000,
    );
    return () => clearInterval(timer);
  });

  // use Clarity
  useEffect(() => {
    if (USE_CLARITY) {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
        t = l.createElement(r);
        t.async = 1;
        t.src = `https://www.clarity.ms/tag/${i}`;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      }(window, document, 'clarity', 'script', process.env.REACT_APP_CLARITY_TRACKING_ID));
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <AppRoute />
        {USE_APPD && <AppDynamicsRUM />}
        <InactivityHandler />
        {systemMsg && (
          <Snackbar
            open
            onClose={onCloseError}
            severity="error"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={15000}
          >
            <Alert severity={systemMsgSeverity} onClose={onCloseError}>
              {systemMsg}
            </Alert>
          </Snackbar>
        )}
        {isFetchingQueue > 0 && (
          <Spinner />
        )}
      </ThemeProvider>
      <ConfirmDialog />
    </>
  );
}

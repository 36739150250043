import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import store from 'app/store';
import { ACCESS_DENIED_ON_CARD_CLICK, CARD_WITH_CONTACT_POPUP, HOME_PAGE, HOME_PAGE_TITLE_DARK } from 'common/Constants';
import { resetEvaluation } from 'features/evaluation/state/reducer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackVisit } from 'features/common/state/service';
import { useSelector } from 'react-redux';
import SimpleDialog from 'common/components/SimpleDialog';

const NavigateCardTitle = styled('h2')(() => ({
  color: HOME_PAGE_TITLE_DARK,
  fontSize: '1.25rem',
  marginBlockStart: '0rem',
  marginBlockEnd: '0rem',
}));

const CustomCard = styled('div')(() => ({
  width: '24rem',
  padding: '1.5rem',
  borderRadius: '0.5rem',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
}));

const CardTitle = styled('div')(() => ({
  fontSize: '1.25rem',
  alignItems: 'center',
  flexDirection: 'row',
}));

const CardContent = styled('div')(() => ({
  marginBottom: '1rem',
}));

export default function NavigationCard({ detail }) {
  const navigate = useNavigate();
  const permissions = useSelector((state) => state.auth.permissions);

  const [contactToSalesPopUp, setContactToSalesPopUp] = useState(false);

  const handleNavigate = (link, trackId) => {
    if (detail.key !== 'search') {
      store.dispatch(resetEvaluation());
    }
    store.dispatch(trackVisit(trackId));
    navigate(link, { state: { source: HOME_PAGE } });
  };

  const handleCloseDialog = () => {
    setContactToSalesPopUp(false);
  };

  const handleButtonClick = () => {
    if (Object.keys(CARD_WITH_CONTACT_POPUP).includes(detail.key)
      && !permissions.includes(CARD_WITH_CONTACT_POPUP[detail.key].permissions)) {
      setContactToSalesPopUp(true);
      store.dispatch(trackVisit(CARD_WITH_CONTACT_POPUP[detail.key].visitType));
      return;
    }
    if (detail.link) {
      handleNavigate(detail.link, detail.trackVisit);
    }
    if (detail.onClickHandler) {
      detail.onClickHandler();
      store.dispatch(trackVisit(detail.trackVisit));
    }
  };

  return (
    <>
      <CustomCard id={`home-${detail.buttonText.replace(/ /g, '-')}-card`}>
        <CardTitle>
          <Grid container item>
            <Grid item sx={{ paddingRight: '0.5rem', display: 'flex', alignItems: 'center' }}>
              <detail.icon color={HOME_PAGE_TITLE_DARK} />
            </Grid>
            <Grid item>
              <NavigateCardTitle>{detail.title}</NavigateCardTitle>
            </Grid>
          </Grid>
          <CardContent>
            <Typography>{detail.content}</Typography>
          </CardContent>
          <Grid
            container
            sx={{
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              id={detail.key}
              onClick={handleButtonClick}
            >
              {detail.buttonText}
            </Button>
          </Grid>
        </CardTitle>
      </CustomCard>
      <SimpleDialog
        open={contactToSalesPopUp}
        onClose={handleCloseDialog}
        message={ACCESS_DENIED_ON_CARD_CLICK} />
    </>
  );
}

import React, { useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import Close from '@mui/icons-material/Close';
import { INACTIVITY_DIALOG, INACTIVITY_SIGNOUT_TIME } from 'common/Constants';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';

export default function InactivityDialog({ handleOnClose }) {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      navigate(RoutePath.portal.path);
    }, INACTIVITY_SIGNOUT_TIME); // 2 minutes

    return () => {
      clearTimeout(timeoutRef?.current);
    };
  }, []);

  const onClose = () => {
    clearTimeout(timeoutRef?.current);
    handleOnClose();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth id="inactivity-dialog">
      <DialogTitle onClose={onClose}>
        <WarningIcon sx={{ transform: 'translate(-5px, 5px)' }} />
        {INACTIVITY_DIALOG.title}
        <Box position="absolute" top={0} right={0}>
          <IconButton id="inactive-close-button" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container flexDirection="row" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              {INACTIVITY_DIALOG.text}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          {INACTIVITY_DIALOG.buttonLebel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

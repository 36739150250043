import * as React from 'react';
import {
  ListItemButton,
  ListItemText,
  SvgIcon,
  Tooltip,
  Stack,
} from '@mui/material';
import CountBadge from 'common/components/CountBadge';

export default function ParentMenuItem(props) {
  const { isMenuSelected, menuIcon, menuText, tooltipText,
    sideBarOpen, onMenuCLick, itemCount, endIcon } = props;
  const sx = {
    menuItem: {
      backgroundColor: '',
    },
    selectedMenuItem: {
      backgroundColor: 'primary.main',
      '&:hover': {
        backgroundColor: 'primary.main',
      },
    },
  };

  return (
    <ListItemButton
      sx={isMenuSelected ? sx.selectedMenuItem : sx.menuItem}
      onClick={() => {
        onMenuCLick();
      }}>
      <Tooltip title={!sideBarOpen ? tooltipText || menuText : ''}>
        <SvgIcon sx={{ marginRight: '0.5rem', color: isMenuSelected ? 'white' : 'black' }}>
          {menuIcon}
        </SvgIcon>
      </Tooltip>
      <Stack direction="row" spacing={1}>
        <ListItemText
          primary={menuText}
          sx={{ opacity: sideBarOpen ? 1 : 0 }}
          primaryTypographyProps={{ fontSize: '0.85rem', color: isMenuSelected ? 'white' : 'black' }}
        />
        {(itemCount >= 0) && <CountBadge count={itemCount} />}
        <SvgIcon sx={{ fontSize: '1.25rem', marginRight: '0.5rem', color: isMenuSelected ? 'white' : 'black' }}>
          {endIcon}
        </SvgIcon>
      </Stack>
    </ListItemButton>
  );
}

import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import Navigator from 'features/navigation';
import Search from 'features/search';
import Admin from 'features/admin';
import Evaluation from 'features/evaluation';
import ViewEvaluation from 'features/evaluation/component/ViewEvaluation';
import CreateEvaluation from 'features/evaluation/component/CreateEvaluation';
import SSO from 'features/sso';
import SsoLogin from 'features/login/sso';
import Download from 'features/download';
import Weights from 'features/weights/weights';
import Home from 'features/home';
import SsoRedirection from 'features/login/ssoRedirection';
import PermissionDenied from 'features/error/PermissionDenied';
import { PERMISSION } from 'common/Constants';
import CreatePopulationStudy from 'features/evaluation/component/population/create-view/CreatePopulationStudy';
import CreateUtilizationStudy from 'features/evaluation/component/population/create-view/CreateUtilizationStudy';
import CensusFiles from 'features/census/CensusFiles';
import PortalRedirection from 'features/login/portalRedirection';
import ReleaseNotes from 'features/releaseNotes';
import ClaimsFiles from 'features/claims/ClaimsFiles';
import PrivateRoute from './PrivateRoute';
import RoutePath from './RoutePath';
import PermissionRoute from './PermissionRoute';

export default function AppRoute() {
  return useRoutes([
    {
      path: '*',
      element: <SsoRedirection />,
    },
    {
      path: RoutePath.sso.path,
      element: <SSO />,
    },
    {
      path: RoutePath.ssoLogin.path,
      element: <SsoLogin />,
    },
    {
      path: RoutePath.portal.path,
      element: <PortalRedirection />,
    },
    {
      path: RoutePath.app.path,
      element: (
        // eslint-disable-next-line react/jsx-indent
        <PrivateRoute>
          <Navigator />
        </PrivateRoute>
      ),
      children: [
        {
          path: RoutePath.app.search.subpath,
          element: (
            <PermissionRoute permission={PERMISSION.VIEW_SEARCH}>
              <Search />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpath,
          element: (
            <PermissionRoute permission={PERMISSION.VIEW_INTELLIGENCE}>
              <Evaluation />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpage.detail.path,
          element: (
            <PermissionRoute permission={PERMISSION.VIEW_INTELLIGENCE}>
              <ViewEvaluation />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpage.createMarketStudy.path,
          element: (
            <PermissionRoute permission={PERMISSION.VIEW_INTELLIGENCE} key="market">
              <CreateEvaluation studyType="market" />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpage.createProviderStudy.path,
          element: (
            <PermissionRoute permission={PERMISSION.VIEW_INTELLIGENCE} key="provider">
              <CreateEvaluation studyType="provider" />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpage.createPopulationStudy.path,
          element: (
            <PermissionRoute permission={PERMISSION.POPULATION_STUDY} key="population">
              <CreatePopulationStudy />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpage.createUtilizationStudy.path,
          element: (
            <PermissionRoute permission={PERMISSION.UTILIZATION_STUDY} key="utilization">
              <CreateUtilizationStudy />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpage.censusFiles.path,
          element: (
            <PermissionRoute permission={PERMISSION.POPULATION_STUDY} key="census">
              <CensusFiles />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.evaluation.subpage.claimsFiles.path,
          element: (
            <PermissionRoute permission={PERMISSION.UTILIZATION_STUDY} key="claimsfiles">
              <ClaimsFiles />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.admin.path,
          element: (
            <PermissionRoute permission={PERMISSION.ADMIN}>
              <Admin />
            </PermissionRoute>
          ),
          children: [
            {
              path: RoutePath.app.admin.networkMapping.path,
              element: <Admin />,
            },
            {
              path: RoutePath.app.admin.user.path,
              element: <Admin />,
            },
          ],
        },
        {
          path: RoutePath.app.download.subpath,
          element: <Navigate to={`../${RoutePath.app.export.subpath}`} replace />,
        },
        {
          path: RoutePath.app.export.subpath,
          element:
          (
            <PermissionRoute permission={PERMISSION.DOWNLOAD_SEARCH}>
              <Download />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.weights.subpath,
          element: (
            <PermissionRoute permission={PERMISSION.CREATE_INTELLIGENCE}>
              <Weights />
            </PermissionRoute>
          ),
        },
        {
          path: RoutePath.app.releaseNotes.subpath,
          element: (
            <ReleaseNotes />
          ),
        },
        {
          path: RoutePath.app.home.subpath,
          element: <Home />,
        },
        {
          path: RoutePath.app.error.subpath,
          element: <PermissionDenied />,
        },
        {
          path: RoutePath.app.resources.subpage.releaseNotes.path,
          element: (
            <ReleaseNotes />
          ),
        },
      ],
    },
  ]);
}

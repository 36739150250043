import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import {
  LinePlot,
  MarkPlot,
} from '@mui/x-charts';
// passing xAxisData='p1', 'p2', 'p3', 'p4' values becasue there is no xAxis value.
const xAxis = ['p1', 'p2', 'p3', 'p4'];

export default function CustomTinyLineChart({ xAxisData = xAxis, yAxisData }) {
  const theme = useTheme();
  return (
    <ChartContainer
      width={180}
      height={120}
      series={[{ type: 'line', color: theme.palette.accent.shade400, data: [...yAxisData] }]}
      xAxis={[{ scaleType: 'point', data: [...xAxisData] }]}
      disableAxisListener
    >
      <LinePlot />
      <MarkPlot />
    </ChartContainer>
  );
}

import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import { Grid } from '@mui/material';
import store from 'app/store';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { confirmDialog } from 'common/components/ConfirmationDialog';
import { deleteNetwork, getNetworks, saveNetwork, updateNetwork } from 'features/common/state/service';
import NetworkDialog from './components/networkDialog';

export default function Settings() {
  const networks = useSelector((state) => state.provider.networks);

  const [networkOpen, setNetworkOpen] = useState(false);
  const [cloneNetwork, setCloneNetwork] = useState({});

  const handleNetworkClose = (data, isNew = false) => {
    if (data && isNew) {
      store.dispatch(saveNetwork(data));
    } else if (data) {
      store.dispatch(updateNetwork(data));
    }

    setCloneNetwork({});
    setNetworkOpen(false);
  };

  const handleNetworkOpen = (data) => {
    setCloneNetwork(data);
    setNetworkOpen(true);
  };

  const deleteByNetworkId = (id) => {
    confirmDialog(() => store.dispatch(deleteNetwork(id)));
  };

  const refreshNetworks = () => {
    store.dispatch(getNetworks());
  };

  React.useEffect(() => {
    refreshNetworks();
  }, []);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <CustomizedMaterialTable
            title="Networks"
            options={{
              search: true,
              pageSizeOptions: [10, 20, 50],
              pageSize: 10,
              paging: true,
              actionsColumnIndex: -1,
            }}
            columns={[
              { title: 'Code', field: 'code' },
              { title: 'Label', field: 'label' },
              {
                title: 'States',
                field: 'states',
                render: (rd) => rd.states.join(', '),
              },
              { title: 'All States', field: 'allState' },
            ]}
            data={networks}
            actions={[
              {
                position: 'row',
                icon: () => <EditIcon />,
                tooltip: 'Edit',
                onClick: (_, rowData) => {
                  handleNetworkOpen({ ...rowData });
                },
              },
              {
                position: 'row',
                icon: () => <DeleteIcon />,
                tooltip: 'Delete',
                onClick: (_, rowData) => deleteByNetworkId(rowData.code),
              },
              {
                icon: () => <RefreshIcon />,
                tooltip: 'Refresh Data',
                onClick: () => {
                  refreshNetworks();
                },
                isFreeAction: true,
              },
              {
                icon: () => <AddIcon />,
                tooltip: 'Add',
                isFreeAction: true,
                onClick: () => {
                  handleNetworkOpen(null);
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      <NetworkDialog
        open={networkOpen}
        onClose={handleNetworkClose}
        target={cloneNetwork}
      />
    </>
  );
}

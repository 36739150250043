/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { MAIN_HOST } from 'common/AppConstant';

export const getAllReleaseNotesPDFLinks = async () => {
  const response = await axios.get(`${MAIN_HOST}/release-note/all`);
  return response.data;
};

export const getReleaseNote = async (filePath) => {
  const formData = new FormData();
  formData.append('filePath', filePath);
  const response = await axios.post(`${MAIN_HOST}/release-note/get-file`, formData, { responseType: 'blob' });
  return response.data;
};

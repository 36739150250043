import axios from 'axios';
import store from 'app/store';
import { decreaseFetchingQueue } from 'app/state/reducer';
import { debounce } from 'lodash';
import { MAIN_HOST } from './AppConstant';
import { DIRECTION_DESC, TABULAR_VIEWS } from './Constants';

// reusable function to get data for views built on material table
export default function getDataForTabularViews(viewName, columns, query, data = {}) {
  return new Promise((resolve) => {
    const view = TABULAR_VIEWS(data)[viewName];
    const result = {
      data: [],
      page: 0,
      totalCount: 0,
    };

    const request = {
      page: query.page,
      size: query.pageSize,
      searchTerm: query.search,
      sortProperty:
        Array.isArray(query.orderByCollection) && query.orderByCollection.length === 1
          ? columns[query.orderByCollection[0].orderBy].field
          : view.defaultSortField,
      direction:
        Array.isArray(query.orderByCollection)
          && query.orderByCollection.length === 1
          && query.orderByCollection[0].orderDirection
          ? query.orderByCollection[0].orderDirection.toUpperCase()
          : DIRECTION_DESC,
    };
    axios
      .post(`${MAIN_HOST}${view.endPoint}`, request)
      .then((apiResponse) => {
        const responseData = apiResponse.data;
        result.data = responseData.content;
        result.page = responseData.number;
        result.size = responseData.size;
        result.totalCount = responseData.totalElements;
      })
      .catch(() => store.dispatch(decreaseFetchingQueue()))
      .finally(() => resolve(result));
  });
}

/**
 * Get file content with pagination and filtered data
 */
export function getFileContent(
  query,
  filePath,
  setIsLoadingData,
  rowID,
  currentPage,
  setCurrentPage,
  initialRowID,
  prevResult,
  setResult,
) {
  return new Promise((resolve) => {
    const rowIDRef = rowID;
    let debounceTime = 0;
    if (query.search.length > 0 && query.search.length < 3) {
      resolve(prevResult);
      return;
    }
    if (query.search.length > 0) debounceTime = 250;

    debounce(() => {
      const clickedNextPage = currentPage < query.page;
      let rowIDValue = clickedNextPage ? rowID.current.nextID : rowID.current.prevID[query.page];
      if (query.page === 0) {
        rowIDValue = 0;
        rowIDRef.current = initialRowID;
      }

      setIsLoadingData(true);
      const request = {
        filePath,
        pageSize: query.pageSize,
        rowID: rowIDValue,
        searchTerm: query.search?.toUpperCase(),
      };
      const result = {
        data: [],
        page: query.page,
        totalCount: 0,
      };

      axios
        .post(`${MAIN_HOST}/claims/file-content/claim-summary-file`, request)
        .then((apiResponse) => {
          const responseData = apiResponse.data;
          result.data = responseData.content;
          result.page = query.page;
          result.totalCount = responseData.total;
          const lastIndex = responseData.content.length - 1;

          const updatedRowID = {
            nextID: responseData.content[lastIndex]?.id,
            prevID: {
              ...rowID.current.prevID,
              [query.page]: clickedNextPage
                ? rowID.current.nextID
                : rowID.current.prevID[query.page],
            },
          };
          rowIDRef.current = updatedRowID;
          setCurrentPage(query.page);
        })
        .catch(() => {
          store.dispatch(decreaseFetchingQueue());
          setIsLoadingData(false);
        })
        .finally(() => {
          setResult(result);
          setIsLoadingData(false);
          resolve(result);
        });
    }, debounceTime)();
  });
}

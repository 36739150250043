import React from 'react';
import { Typography, Table, TableHead, TableRow, Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { EVALTYPE, DUR_GOLDEN_STAR_COLOR, TOOLTIP_MSG } from 'common/Constants';
import { CustomNetworkResultsHeaderTheme } from 'features/common/Style';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { NetworkSubColumnCell } from './PopulationStyles';

function Column({ columnName, tooltipText, width, align = 'center', sx = {} }) {
  return (
    <NetworkSubColumnCell width={width} align={align} padding="none" sx={sx}>
      <Tooltip key={columnName} title={tooltipText} placement="top">
        <Typography variant="paraHeader">{columnName}</Typography>
      </Tooltip>
    </NetworkSubColumnCell>
  );
}

function StudyTableColumnNames() {
  return (
    <>
      <Column width="20%" columnName="% Medicare" tooltipText={TOOLTIP_MSG().VIEW_PROVIDER_OR_MARKET_STUDY.PERCENTAGE_MEDICARE} />
      <Column width="40%" columnName="MRF Rate" align="right" tooltipText={TOOLTIP_MSG().VIEW_PROVIDER_OR_MARKET_STUDY.MRF_RATE} />
      <Column
        width="20%"
        columnName="NPI Count"
        align="right"
        sx={{ paddingLeft: '15px' }}
        tooltipText={TOOLTIP_MSG().VIEW_PROVIDER_OR_MARKET_STUDY.NPI_COUNT}
      />
      <Column
        width="20%"
        columnName={<StarIcon width="20%" sx={{ color: DUR_GOLDEN_STAR_COLOR, verticalAlign: 'bottom !important' }} />}
        sx={{ paddingLeft: '15px' }}
        tooltipText={TOOLTIP_MSG().VIEW_PROVIDER_OR_MARKET_STUDY.STAR_SYMBOL}
      />
    </>
  );
}

const EvalTypeColumnNames = {
  [EVALTYPE.POPULATION]: () => (
    <>
      <Column width="35%" columnName="% Medicare" tooltipText={TOOLTIP_MSG().VIEW_POPULATION_STUDY.PERCENTAGE_MEDICARE} />
      <Column width="30%" columnName="NPI Count" tooltipText={TOOLTIP_MSG().VIEW_POPULATION_STUDY.NPI_COUNT} />
      <Column width="35%" columnName="Data Usability" tooltipText={TOOLTIP_MSG().VIEW_POPULATION_STUDY.DATA_USABILITY} />
    </>
  ),
  [EVALTYPE.PROVIDER]: () => <StudyTableColumnNames />,
  [EVALTYPE.MARKET]: () => <StudyTableColumnNames />,
};

export default function NetworkResultsHeader({ evalType }) {
  const customAppTheme = useTheme();
  const pageTheme = evalType !== EVALTYPE.POPULATION
    ? CustomNetworkResultsHeaderTheme(customAppTheme) : customAppTheme;
  return (
    <ThemeProvider theme={pageTheme}>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow sx={{ display: 'flex', alignItems: 'flex-end', verticalAlign: 'bottom !important', height: '40px' }}>
            {EvalTypeColumnNames[evalType]()}
          </TableRow>
        </TableHead>
      </Table>
    </ThemeProvider>
  );
}

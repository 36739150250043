import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Tooltip } from '@mui/material';

const TagStyledButton = styled(Button)({
  border: '0px !important',
  borderRadius: '.5rem !important',
  textTransform: 'none !important',
  color: '#212121 !important',
  whiteSpace: 'nowrap',
  padding: '4px 5px !important',
  marginInlineEnd: '.5rem !important',
});

export default function TagButton({ label, variant, onClick, tooltipText }) {
  return (
    <TagStyledButton
      color="tag"
      size="small"
      variant={variant}
      onClick={() => onClick()}
      disableElevation
    >
      <Tooltip title={tooltipText} placement="top">
        <span>{label}</span>
      </Tooltip>
    </TagStyledButton>
  );
}

import React, { useEffect } from 'react';
import Spinner from 'common/components/spinner';
import { CLIENT_PORTAL, EMPLOYEE_PORTAL } from 'common/AppConstant';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { resetJwt, resetUser } from './state/reducer';

export const routeMap = {
  CLIENT: CLIENT_PORTAL,
  EMPLOYEE: EMPLOYEE_PORTAL,
  // Add more user types and route paths as needed
};
export default function PortalRedirection() {
  const userType = useAppSelector((state) => state.auth.userType);
  const dispatch = useAppDispatch();
  // Redirect to the respective portal upon loading the app
  useEffect(() => {
    localStorage.clear();
    dispatch(resetJwt());
    dispatch(resetUser());
    if (!routeMap[userType?.toUpperCase()]) {
      // for local it goes to okta so keep it
      // to do use env variable
      // window.location.href = MULTIPLAN_OKTA_SSO;

      // In dev or oher env it should be
      window.location.href = CLIENT_PORTAL;
    } else {
      window.location.href = routeMap[userType?.toUpperCase()];
    }
  }, []);

  return (
    <Spinner />
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { PANORAMA_VERSION_ONE } from 'common/Constants';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    systemMsg: null,
    systemMsgSeverity: 'error',
    isFetchingQueue: 0,
    showLogoutDialog: false,
    inlinePageError: null,
    panoramaVersion: PANORAMA_VERSION_ONE,
  },
  reducers: {
    updateSystemErrorMsg(state, action) {
      state.systemMsg = action.payload;
      state.systemMsgSeverity = 'error';
    },
    updateSystemMsg(state, action) {
      state.systemMsg = action.payload;
      state.systemMsgSeverity = 'info';
    },
    increaseFetchingQueue(state) {
      state.isFetchingQueue += 1;
    },
    decreaseFetchingQueue(state) {
      state.isFetchingQueue -= 1;
    },
    setInlinePageError(state, action) {
      state.inlinePageError = action.payload;
    },
    clearInlinePageError(state) {
      state.inlinePageError = null;
    },
    setPanoramaVersion(state, action) {
      state.panoramaVersion = action.payload;
    },
  },
});

export const {
  updateSystemErrorMsg, updateSystemMsg, increaseFetchingQueue, decreaseFetchingQueue,
  setInlinePageError, clearInlinePageError, setPanoramaVersion,
} = appSlice.actions;
export default appSlice.reducer;

/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { createTheme } from '@mui/material/styles';
import RoutePath from 'app/router/RoutePath';
import { ReactComponent as SearchRatesSvgIcon } from '../asset/list-search.svg';
import { ReactComponent as IntelligenceSvgIcon } from '../asset/device-analytics.svg';
import { ReactComponent as MailSvgIcon } from '../asset/mail-icon.svg';
import { openCustomerService } from './util/commonUtil';
import SupportCardContent from './components/SupportCardContent';
import { SHOW_UTILIZATION_STUDY } from './AppConstant';

export const PRIMARY_DARK = '#002D74';

export const PRIMARY = '#1976D2';

export const PRIMARY_WARNING = '#ff1744';

export const PRIMARY_LIGHT = '#AECFEF';

export const PRIMARY_DEEP_DARK = '#002D74';

export const CHECK_GREEN = '#00BE5F';

export const DIRECTION_DESC = 'DESC';

export const DIRECTION_ASC = 'ASC';

export const COLOR_PROCESSING = '#F2DF00';

export const TEXT_BLACK = '#222222';

export const PRIMARY_GRAY = '#999999';

export const DARK_GRAY = '#D0D3DB';

export const HOME_PAGE_TITLE_DARK = '#002E74';

export const DIVIDER_SOLID = '#929CA5';

export const PLUS_MINUS_DARK = '#212121';

export const PLUS_MINUS_GREEN = '#C4EBDD';

export const PLUS_MINUS_RED = '#FDF3D7';

export const MAX_KEYWORD_LENGTH = 5000;

export const LONG_KEYWORD_ERR_MSG = 'Too many parameters in your search criteria. Please refine your search.';

export const RESPONSE_STATUS_SUCCESS = 'success';

export const WHITE = '#FFFF';

export const LIGHTER_GRAY_200 = '#E6EAEF';

export const LIGHTER_GRAY_300 = '#C7D0DB';

export const PRIMARY_RED = '#eb4034';

export const NAVIGATION_AWAY_WARNING_TITLE_MSG = 'Are you sure you want to cancel?';

export const NAVIGATION_AWAY_WARNING_BODY_MSG = 'You will lose any selections or data entered.';

export const NAVIGATION_AWAY_CONFIRM_BUTTON = 'Yes, cancel';

export const NAVIGATION_AWAY_CANCEL_BUTTON = 'Remain on page';

export const COLOR_OUTLINE_GREY = '#6A7585';

export const blueTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_LIGHT,
      dark: PRIMARY_LIGHT,
    },
  },
});

export const EVALUATION_STATUS = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
};

export const REQUIRED_FILE_STATUS = {
  CREATED: { code: 'CREATED', displayName: 'Created' },
  IN_PROGRESS: { code: 'IN_PROGRESS', displayName: 'In Progress' },
  COMPLETED: { code: 'COMPLETED', displayName: 'Completed' },
  ERROR: { code: 'ERROR', displayName: 'Error' },
};

export const EVALTYPE = {
  MARKET: 'MARKET',
  PROVIDER: 'TAXID',
  POPULATION: 'POPULATION',
  UTILIZATION: 'UTILIZATION',
};

export const TRACK_VISIT_TYPE = {
  EVALUATION: 'EVALUATION_LIST',
  DOWNLOAD: 'FILE_DOWNLOAD_LIST',
  SEARCH: 'SEARCH',
  WEIGHT: 'WEIGHT_LIST',
  HOME_SEARCH: 'HOME_SEARCH',
  HOME_PROVIDER_STUDY: 'HOME_PROVIDER_STUDY',
  HOME_MARKET_STUDY: 'HOME_MARKET_STUDY',
  HOME_POPULATION_STUDY: 'HOME_POPULATION_STUDY',
  HOME_UTILIZATION_STUDY: 'HOME_UTILIZATION_STUDY',
  HOME_SERVICE_CASE: 'HOME_SERVICE_CASE',
  HOME_UTILIZATION_STUDY_DENIED: 'HOME_UTILIZATION_STUDY_DENIED',
  HOME_POPULATION_STUDY_DENIED: 'HOME_POPULATION_STUDY_DENIED',
  routePath: {
    [RoutePath.app.evaluation.path]: 'EVALUATION_LIST',
    [RoutePath.app.evaluation.subpage.createMarketStudy.path]: 'MARKET_STUDY_CREATE',
    [RoutePath.app.evaluation.subpage.createProviderStudy.path]: 'PROVIDER_STUDY_CREATE',
    [RoutePath.app.evaluation.subpage.createPopulationStudy.path]: 'POPULATION_STUDY_CREATE',
    [RoutePath.app.evaluation.subpage.createUtilizationStudy.path]: 'UTILIZATION_STUDY_CREATE',
    [RoutePath.app.evaluation.subpage.censusFiles.path]: 'CENSUS_FILES_LIST',
    [RoutePath.app.resources.subpage.releaseNotes.path]: 'RELEASE_NOTES_PAGE',
    [RoutePath.app.evaluation.subpage.claimsFiles.path]: 'CLAIMS_FILES_LIST',
  },
  rerunStudy: {
    [EVALTYPE.PROVIDER]: 'PROVIDER_STUDY_RERUN',
    [EVALTYPE.MARKET]: 'MARKET_STUDY_RERUN',
    [EVALTYPE.POPULATION]: 'POPULATION_STUDY_RERUN',
  },
  cloneStudy: {
    [EVALTYPE.PROVIDER]: 'PROVIDER_STUDY_CLONE',
    [EVALTYPE.MARKET]: 'MARKET_STUDY_CLONE',
    [EVALTYPE.POPULATION]: 'POPULATION_STUDY_CLONE',
  },
  exportImage: {
    [EVALTYPE.PROVIDER]: 'PROVIDER_STUDY_EXPORT_IMAGE',
    [EVALTYPE.MARKET]: 'MARKET_STUDY_EXPORT_IMAGE',
    [EVALTYPE.POPULATION]: 'POPULATION_STUDY_EXPORT_IMAGE',
  },
  exportNewCsv: {
    [EVALTYPE.PROVIDER]: 'PROVIDER_STUDY_NEW_EXPORT_CSV',
    [EVALTYPE.MARKET]: 'MARKET_STUDY_NEW_EXPORT_CSV',
    [EVALTYPE.POPULATION]: 'POPULATION_STUDY_EXPORT_CSV',
  },
  exportOldCsv: {
    [EVALTYPE.PROVIDER]: 'PROVIDER_STUDY_OLD_EXPORT_CSV',
    [EVALTYPE.MARKET]: 'MARKET_STUDY_OLD_EXPORT_CSV',
  },
};

export const PERMISSION = {
  ADMIN: 'admin',
  CREATE_INTELLIGENCE: 'CREATE_INTELLIGENCE',
  POPULATION_STUDY: 'POPULATION_STUDY',
  UTILIZATION_STUDY: 'UTILIZATION_STUDY',
  DOWNLOAD_SEARCH: 'DOWNLOAD_SEARCH',
  VIEW_INTELLIGENCE: 'VIEW_INTELLIGENCE',
  VIEW_OPTIMIZE: 'VIEW_OPTIMIZE',
  VIEW_SEARCH: 'VIEW_SEARCH',
};

export const BILLING_CLASS = {
  INSTITUTIONAL: 'INSTITUTIONAL',
  PROFESSIONAL: 'PROFESSIONAL',
};

export const HOME_PAGE_CARD_MAP = [
  {
    key: 'population',
    title: 'Member Population Analysis',
    content: 'Upload a census file with member counts by zip code to determine Metropolitan Statistical Areas (MSAs) to analyze. This study will show plan rates for 3 consolidated procedure code categories.',
    buttonText: 'create population study',
    icon: IntelligenceSvgIcon,
    link: RoutePath.app.evaluation.subpage.createPopulationStudy.path,
    trackVisit: TRACK_VISIT_TYPE.HOME_POPULATION_STUDY,
    show: true,
  },
  {
    key: 'utilization',
    title: 'Claims Analysis',
    content: 'Upload a claims summary file for use in a Utilization Study. This study will compare the rates for utilized procedure codes for up to 4 plans.',
    buttonText: 'create utilization study',
    icon: IntelligenceSvgIcon,
    link: RoutePath.app.evaluation.subpage.createUtilizationStudy.path,
    trackVisit: TRACK_VISIT_TYPE.HOME_UTILIZATION_STUDY,
    show: SHOW_UTILIZATION_STUDY,
  },
  {
    key: 'market',
    title: 'Market Based Analysis',
    content: 'Select up to 4 plans to compare rates paid in an MSA for 15 procedure code categories. The Data Usability Rating will help you select plans with sufficient rate data.',
    buttonText: 'create market study',
    icon: IntelligenceSvgIcon,
    link: RoutePath.app.evaluation.subpage.createMarketStudy.path,
    permission: PERMISSION.CREATE_INTELLIGENCE,
    trackVisit: TRACK_VISIT_TYPE.HOME_MARKET_STUDY,
    show: true,
  },
  {
    key: 'provider',
    title: 'Provider Specific Analysis',
    content: 'Select up to 4 plans to analyze published price transparency rates for up to 10 providers.',
    buttonText: 'create provider study',
    icon: IntelligenceSvgIcon,
    link: RoutePath.app.evaluation.subpage.createProviderStudy.path,
    permission: PERMISSION.CREATE_INTELLIGENCE,
    trackVisit: TRACK_VISIT_TYPE.HOME_PROVIDER_STUDY,
    show: true,
  },
  {
    key: 'search',
    title: 'Learn About Rates',
    content: 'Research market rates by payor/plan, billing codes and providers.',
    buttonText: 'search rates',
    icon: SearchRatesSvgIcon,
    link: RoutePath.app.search.path,
    permission: PERMISSION.VIEW_SEARCH,
    trackVisit: TRACK_VISIT_TYPE.HOME_SEARCH,
    show: true,
  },
  {
    key: 'servicecase',
    title: 'Get help with PlanOptix',
    content: <SupportCardContent />,
    buttonText: 'create service case',
    icon: MailSvgIcon,
    onClickHandler: openCustomerService,
    trackVisit: TRACK_VISIT_TYPE.HOME_SERVICE_CASE,
    show: true,
  },
];

export const CARD_WITH_CONTACT_POPUP = {
  utilization: {
    permissions: PERMISSION.UTILIZATION_STUDY,
    visitType: TRACK_VISIT_TYPE.HOME_UTILIZATION_STUDY_DENIED,
  },
  population: {
    permissions: PERMISSION.POPULATION_STUDY,
    visitType: TRACK_VISIT_TYPE.HOME_POPULATION_STUDY_DENIED,
  },
};

export const ACCESS_DENIED_ON_CARD_CLICK = 'Contact Claritev Sales to gain access to this feature.';

export const PAGE_NAME = {
  HOME: 'home',
  SEARCH: 'search',
  EVALUATION: 'evaluation',
  CREATE_PROVIDER_STUDY: 'create-provider-study',
  CREATE_MARKET_STUDY: 'create-market-study',
  EXPORT: 'export',
  WEIGHTS: 'weights',
  ADMIN: 'admin',
  RESOURCES: 'resources',
};

export const OUTLIER_INDICATOR_DISPLAY = {
  '-1': 'Low',
  0: 'No',
  1: 'High',
};
// change me later if NPI is added back
export const EVALTYPE_MAP = {
  TAXID: 'Provider',
  MARKET: 'Market',
  NPI: 'Provider',
  POPULATION: 'Population',
  UTILIZATION: 'Utilization',
};

export const EVALTYPE_ROUTE_MAP = {
  TAXID: RoutePath.app.evaluation.subpage.createProviderStudy.path,
  MARKET: RoutePath.app.evaluation.subpage.createMarketStudy.path,
  POPULATION: RoutePath.app.evaluation.subpage.createPopulationStudy.path,
};

export const FILE_UPLOAD_PROPS = {
  customWeights: {
    download: 'sample_customized_weight.csv',
    href: '/sample_customized_weight.csv',
    dialogTitle: 'Custom Weights',
    textFieldLabel: 'Weights Name',
    hintText: 'customized weights',
    maxFileSize: 5,
  },
  census: {
    download: 'sample_population_census_summary.csv',
    href: '/sample_population_census_summary.csv',
    dialogTitle: 'Population Census Summary',
    textFieldLabel: 'Census Summary Name',
    hintText: 'population census summary',
    maxFileSize: 5,
  },
};

export const SHOW_ALL_RESULT = 'All';

export const CATEGORY = 'category';

export const PERCENT_DIFF = 'percentDiff';

export const TABULAR_VIEWS = (data = {}) => ({
  census: {
    tableTitle: 'Census Summary Files',
    endPoint: '/census/list',
    defaultSortField: '',
    studiesListEndPoint: '/census/evaluation-list/',
  },
  claims: {
    tableTitle: 'Claims Summary Files',
    endPoint: '/claims/list',
    defaultSortField: '',
    studiesListEndPoint: '/claims/evaluation-list/',
  },
  weights: {
    studiesListEndPoint: '/weights/custom-weight/evaluations/',
  },
  censusDetail: {
    tableTitle: 'Census Summary',
    endPoint: `/census/details/${data.id}`,
    defaultSortField: '',
  },
});
export const CENSUS_DELETE_CONFIRMATION_MSG = 'Are you sure you want to delete the census summary file with name';

export const PROVIDER_PAGE_SIZE = 30;
export const DEFAULT_PAGE_SIZE = 15;
export const NPI_TYPES = [
  { label: '1 - Individual', value: 1 },
  { label: '2 - Organization', value: 2 },
];
export const VIEW_PLAN_BY_MSA = 'viewPlansByMSA';
export const VIEW_PLAN_BY_STATE = 'viewPlansByState';
export const VIEW_ALL_PLAN = 'viewAllPlans';
export const POPULATION_NETWORK_RESULT = 'populationNetworkResult';
export const PLAN_BY_STATE_MIN_MSA_RATING = 'minMSARating';
export const PLAN_BY_STATE_MAX_MSA_RATING = 'maxMSARating';
export const PR_STATE = 'PR';
export const VI_STATE = 'VI';
export const HOME_ALL_PLANS = 'HOME_ALL_PLANS';
export const HOME_BY_STATE = 'HOME_BY_STATE';
export const HOME_PAGE = 'HOME_PAGE';
export const HOME_BY_MSA = 'HOME_BY_MSA';
export const NPI_NUMBER_LENGTH = 10;
export const TIN_NUMBER_LENGTH = 9;
export const ALERT_BG_COLOR = '#D5E5F1';
export const ALERT_BORDER_COLOR = '#1976D2';
export const ALERT_TEXT_COLOR = '#002E74';
export const STUDY_VERSION_TWO = 2;
export const STUDY_VERSION_ONE = 1;
export const NO_RESULT_TO_DISPLAY = 'No results to display';
export const STUDY_VERSION_TWO_TYPES = [EVALTYPE.MARKET, EVALTYPE.PROVIDER];
export const DUR_GOLDEN_STAR_COLOR = '#CF8500';
export const MAX_NUM_COMPARE_NETWORKS = 4;
export const PRIVACY_POLICY_LINK = 'https://www.claritev.com/privacy-policy/';
export const TERMS_OF_USE_LINK = 'https://www.claritev.com/terms-of-use/';
export const TOOLTIP_MSG = (category) => ({
  TIN_or_NPI: `Use ${category}s to identify providers`,
  NPI_ENTITY_TYPE: 'Restrict NPI types used in the study',
  PROVIDER_NAME: 'Search for providers',
  OUTLIER: 'Rates outside the expected range',
  STATES: 'States represented in the Census Summary',
  TOTAL_MSA: 'MSAs represented in the Census Summary',
  TOTAL_POPULATION: 'Number of people in the Census Summary',
  PLAN_TYPE: 'Select a Plan Type',
  NETWORK_RATES_TO_MEDICARE: 'Network rates to compare to Medicare',
  VIEW_PROVIDER_OR_MARKET_STUDY: {
    WEIGHT: 'Utilization of all codes in the category',
    PERCENTAGE_MEDICARE: 'Comparison to Medicare Rate',
    MRF_RATE: 'Published Network Rate',
    NPI_COUNT: 'Number of NPIs with published rates',
    STAR_SYMBOL: 'A measure of codes with sufficient rate data',
  },
  VIEW_POPULATION_STUDY: {
    POPULATION_COUNT: 'Count of members in the MSA',
    POPULATION_PERCENTAGE: 'Percentage of members in the MSA',
    PERCENTAGE_MEDICARE: 'Comparison to Medicare Rate',
    NPI_COUNT: 'Number of NPIs in the MSA with published rates',
    DATA_USABILITY: 'A measure of codes with sufficient rate data',
  },
});

export const NO_MORE_RECORDS_TO_LOAD = 'No more records to load';
export const NO_RECORDS_TO_DISPLAY = 'No records to display';

export const PANORAMA_VERSION_MAP = {
  1: 'Panorama 1.0',
  2: 'Panorama 2.0',
};

export const PANORAMA_VERSION_ONE = 1;
export const PANORAMA_VERSION_TWO = 2;
export const MARK_ELEMENT_COLOR = '#002E74';
export const LINE_ELEMENT_COLOR = '#929CA5';
export const BLUE_COLOR = '#0000ff';

export const VERSION_URL = {
  [PANORAMA_VERSION_ONE]: '',
  [PANORAMA_VERSION_TWO]: 'v2/',
};

export const VERSION_URL_FOR_SEARCH_RATE = {
  [PANORAMA_VERSION_ONE]: '/state',
  [PANORAMA_VERSION_TWO]: '/mrfRates',
};

export const INACTIVITY_DIALOG = {
  title: 'Inactivity Warning',
  text: 'You have been inactive for 18 minutes. You will be signed out in 2 minutes.',
  buttonLebel: 'Stay Logged In',
};

export const INACTIVITY_WARNING_TIME = 18 * 60 * 1000;
export const INACTIVITY_SIGNOUT_TIME = 2 * 60 * 1000;

export const TYPE_CODE_MAPPING = {
  'Code Category': 'CODE-CAT',
  'Provider Group': 'PROVIDER-GROUPING',
};

export const SORT_KEY_MAPPING = {
  billingcode: 'billingCode',
  billingclass: 'billingClass',
  rate: 'negotiatedRate',
  negotiatedtype: 'negotiatedType',
  negotiationarrangement: 'negotiationArrangement',
  plantype: 'planType',
};
export const SUGGESTION_API = {
  [PANORAMA_VERSION_ONE]: '',
  [PANORAMA_VERSION_TWO]: '/code',
};

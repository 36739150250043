import React, { useState, useEffect } from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import { FORMAT_NATURAL } from 'common/util/commonUtil';
import { getCensusStatistics, getCensusList } from 'features/census/state/service';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FILE_UPLOAD_PROPS } from 'common/Constants';
import * as Constants from 'common/Constants';

export default function CensusStats({ censusObj, dialogTitle }) {
  const { setValue } = useFormContext();
  const [statsObj, setStatsObj] = useState(null);
  const [showStats, setShowStats] = useState(false);
  const dispatch = useAppDispatch();
  const uploadSuccess = useAppSelector((state) => state.evaluation.uploadSuccessFlag);
  const uploadedCensus = useAppSelector((state) => state.census.uploadedCensus);

  const getCensusStatisticsDetail = (id) => {
    try {
      getCensusStatistics(id).then((data) => {
        setStatsObj(data);
        setShowStats(true);
      });
    } catch (error) {
      setShowStats(false);
      setStatsObj(null);
    }
  };

  useEffect(() => {
    if (censusObj && censusObj.id) {
      setShowStats(false);
      getCensusStatisticsDetail(censusObj.id);
    }
  }, [censusObj]);

  useEffect(() => {
    if (uploadSuccess === true && dialogTitle === FILE_UPLOAD_PROPS.census.dialogTitle) {
      dispatch(getCensusList());
      setValue('census', uploadedCensus, { shouldDirty: true });
      getCensusStatisticsDetail(uploadedCensus?.id);
    }
  }, [uploadSuccess]);

  return (
    showStats && (
      <Grid sx={9} container direction="column">
        <Grid item width="100%" sx={9}>
          <Tooltip title={Constants.TOOLTIP_MSG().STATES} placement="top">
            <Typography variant="statsLabel" sx={{ verticalAlign: 'top' }}>
              States:
            </Typography>
          </Tooltip>
          <Typography variant="caption" marginLeft="0.5rem" sx={{ display: 'inline-block', width: '80%', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-line', verticalAlign: 'top' }}>
            {statsObj?.stateList && statsObj.stateList.join(', ')}
          </Typography>
        </Grid>
        <Grid item sx={9}>
          <Tooltip title={Constants.TOOLTIP_MSG().TOTAL_MSA} placement="top">
            <Typography variant="statsLabel">
              Total MSAs:
            </Typography>
          </Tooltip>
          <Typography variant="caption" marginLeft="0.5rem">
            {statsObj.noOfMSA}
          </Typography>
        </Grid>
        <Grid item sx={9}>
          <Tooltip title={Constants.TOOLTIP_MSG().TOTAL_POPULATION} placement="top">
            <Typography variant="statsLabel">
              Total Population:
            </Typography>
          </Tooltip>
          <Typography variant="caption" marginLeft="0.5rem">
            {FORMAT_NATURAL(statsObj.totalPopulation)}
          </Typography>
        </Grid>
      </Grid>
    )
  );
}

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';
import { VERSION_URL } from 'common/Constants';

export const getBannerInfo = createAsyncThunk('home/summary', async (arg, { getState }) => {
  const { panoramaVersion } = getState().app || { panoramaVersion: 1 };
  const response = await axios.get(`${MAIN_HOST}/home/${VERSION_URL[panoramaVersion]}summary`);
  return response.data;
});

export const getTopNetworksInfo = createAsyncThunk('/home/top-networks', async () => {
  const response = await axios.get(`${MAIN_HOST}/home/top-networks`);
  return response.data;
});

export default getTopNetworksInfo;

/*
A reusable component - stack of buttons and a dynamic dropdown based on the selected button.
Refer Code Selection or Provider selection section on search page for look and feel, and usage.
options type: any[], assumed array of { id: string,  value: string, type: string } by default
**To customize the component for different options type and different displays,
props prefixed with return e.g. 'returnChipLabel' can be passed as functions**
*/
import React, { useState, useEffect } from 'react';
import {
  Stack,
  ButtonGroup,
  Tooltip,
  Chip,
} from '@mui/material';
import TagButton from 'common/components/TagButton';
import CustomInfoIcon from './CustomInfoIcon';
import FormAutocompleteTextField from './FormAutocompleteTextField';

export default function MultiCategoryMultiSelect(props) {
  const {
    categories,
    defaultCategory = 'All',
    label,
    infoText,
    fieldName,
    autocompId,
    handleCategoryChange,
    options,
    optionsLoading,
    onValueChange,
    onInputChange,
    returnOptionKey,
    returnOptionLabel,
    returnOptionText,
    returnChipKey,
    returnChipLabel,
    returnChipTooltip,
    isOptionEqualToValue,
    forceCategoryReset,
    autocompProps, // additional need basis autocomplete props
  } = props;

  const [chosenCategory, setChosenCategory] = useState(defaultCategory);

  const onSwitchingCategory = (category) => {
    setChosenCategory(category);
    if (typeof handleCategoryChange === 'function') handleCategoryChange(category);
  };

  useEffect(() => {
    if (forceCategoryReset) onSwitchingCategory(defaultCategory);
  }, [forceCategoryReset]);

  return (
    <Stack spacing={1.5}>
      <ButtonGroup variant="text">
        {categories.map((category) => (
          <TagButton
            key={category}
            color="tag"
            variant={chosenCategory === category ? 'contained' : 'text'}
            onClick={() => onSwitchingCategory(category)}
            label={category}
          />
        ))}
        <CustomInfoIcon title={infoText} />
      </ButtonGroup>
      <FormAutocompleteTextField
        fieldName={fieldName}
        onValueChange={onValueChange}
        autocompleteProps={{
          id: autocompId,
          multiple: true,
          options,
          loading: optionsLoading,
          filterSelectedOptions: true,
          getOptionLabel: (option) => (
            returnOptionLabel ? returnOptionLabel(option) : option.value
          ),
          isOptionEqualToValue: (option, value) => (
            isOptionEqualToValue ? isOptionEqualToValue(option, value)
              : option.value === value.value
          ),
          renderOption: (opProps, option) => (
            <li
              {...opProps}
              className={opProps.className}
              style={{
                color: optionsLoading && 'transparent',
                textShadow: optionsLoading && '0 0 8px #000',
              }}
              key={returnOptionKey ? returnOptionKey(option) : option.value}
            >
              {
                returnOptionText ? returnOptionText(option)
                  : (
                    <span>
                      <b>{option.type}</b>
                      {` - ${option.value}`}
                    </span>
                  )
              }
            </li>
          ),
          renderTags: (value, getTagProps) => (
            Array.isArray(value) && (value.map((option, index) => (
              <Tooltip
                title={returnChipTooltip ? returnChipTooltip(option) : option.value}
                key={returnChipKey ? returnChipKey(option) : option.value}
              >
                <Chip
                  variant="contained"
                  label={returnChipLabel ? returnChipLabel(option) : option.value}
                  {...getTagProps({ index })}
                />
              </Tooltip>
            )))
          ),
          ...autocompProps,
        }}
        textFieldProps={{
          label,
          onChange: (e) => onInputChange(e),
        }}
      />
    </Stack>
  );
}

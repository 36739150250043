import React, { memo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Grid, Typography, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import BookIcon from '@mui/icons-material/MenuBook';
import NavigateAwayWarningDialog from 'common/components/navigateAwayWarningDialog';
import RoutePath from 'app/router/RoutePath';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { convertDatetime, padStartToNumber } from 'common/util/commonUtil';
import { SHOW_PANORAMA_VERSION, ZIP_FILE_EXTENSION } from 'common/AppConstant';
import { useSelector } from 'react-redux';
import { UtilityButton } from 'features/search/component/UtilityButtons';
import store from 'app/store';
import BasicCard from 'common/components/BasicCard';
import { EVALTYPE, EVALTYPE_MAP, EVALTYPE_ROUTE_MAP, NPI_TYPES, STUDY_VERSION_TWO, STUDY_VERSION_TWO_TYPES, ALERT_TEXT_COLOR } from 'common/Constants';
import { BreakWordText } from 'common/components/styledTexts';
import { getCensusStatistics } from 'features/census/state/service';
import { trackVisit } from 'features/common/state/service';
import { exportCSVFileAsZIP } from 'features/evaluation/state/service';
import * as Constants from 'common/Constants';
import handleExport from 'common/util/canvasExportUtil';
import csvExportIcon from 'asset/csv-export-icon.svg';
import EvaluationNpiLinkageDetailDialog from './evaluationNpiLinkageDetailDialog';
import { save } from '../state/service';
import { setPrevVisitState } from '../state/reducer';

function EvaluationBasicInfo({ evaluation, studyRef, selectedTab }) {
  const networks = useSelector((state) => state.provider.networks);
  const allMsaSuggestions = useSelector((state) => state.evaluation.msaSuggestions);
  const stateOptions = useSelector((state) => state.provider.states);
  const header = `${EVALTYPE_MAP[evaluation.evalType]} Study - ${evaluation.name}`;
  const findNetworkMatch = (code) => (networks.find((nw) => nw.code === code)?.label || code);
  const findMSAMatch = (code) =>
    (allMsaSuggestions.find((msa) => msa.code === code)?.label || code);
  const findStateMatch = (code) =>
    (stateOptions.find((state) => state.code === code)?.label || code);
  const [npiDetailOpen, setNpiDetailOpen] = useState(false);
  const [showRerunWarning, setShowRerunWarning] = useState(false);
  const navigate = useNavigate();
  const [censusStats, setCensusStats] = useState(null);
  const [newStudy, setNewStudy] = useState(false);

  useEffect(() => {
    if (evaluation.evalType === EVALTYPE.POPULATION) {
      try {
        getCensusStatistics(evaluation.census?.id).then((data) => {
          setCensusStats(data);
        });
      } catch (error) {
        setCensusStats(null);
      }
    }
    if (STUDY_VERSION_TWO_TYPES.includes(evaluation.evalType)
      && evaluation?.version === STUDY_VERSION_TWO) {
      setNewStudy(true);
    }
  }, []);

  const renderProvidersInfo = () => {
    const providersArray = [];
    evaluation.npis.map((elem) => {
      if (elem.npi.length === Constants.NPI_NUMBER_LENGTH) {
        const providerInfo = elem.npiLabel === null ? `NPI: ${elem.npi}` : `${elem.npiLabel} (NPI: ${elem.npi})`;
        providersArray.push(providerInfo);
      } else if (elem.npi.length === Constants.TIN_NUMBER_LENGTH) {
        const providerInfo = elem.npiLabel === null ? `TIN: ${elem.npi}` : `${elem.npiLabel} (TIN: ${elem.npi})`;
        providersArray.push(providerInfo);
      } else {
        providersArray.push((elem.npiLabel || elem.npi));
      }
      return null;
    });
    return providersArray;
  };

  const comparisonCard = {
    header: 'Networks',
    body1: (
      <>
        {(evaluation.evalType !== EVALTYPE.POPULATION
          && evaluation.version !== Constants.STUDY_VERSION_TWO) && (
            <Grid item container>
              <Grid item xs={4}>
                <Typography variant="cardLabel">Main</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">{findNetworkMatch(evaluation.networkCode)}</Typography>
              </Grid>
            </Grid>
        )}
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">Compare With</Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid>
              <Typography variant="body1">{evaluation.compareNetworks.map((nw) => (findNetworkMatch(nw.networkCode))).join(', ')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    ),
    size: { xs: 12, sm: 10, md: 6, lg: 4 },
  };

  const evaluationInfoForMarketCards = [{
    header: 'Study Criteria',
    body1: (
      <>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">MSA</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{findMSAMatch(evaluation.npis[0]?.npi)}</Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">Weights</Typography>
          </Grid>
          <Grid item xs={8}>
            <BreakWordText variant="body1">{evaluation.customizedWeights?.name}</BreakWordText>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">Outlier Rates</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{parseInt(evaluation.outlier, 10) ? 'Include' : 'Exclude'}</Typography>
          </Grid>
        </Grid>
      </>
    ),
    size: { xs: 12, sm: 10, md: 6, lg: 4 },
  },
  comparisonCard,
  ];

  const evaluationInfoForProviderCards = [{
    header: 'Study Criteria',
    body1: (
      <>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">State</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{findStateMatch(evaluation.state)}</Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">Weights</Typography>
          </Grid>
          <Grid item xs={8}>
            <BreakWordText variant="body1">{evaluation.customizedWeights?.name}</BreakWordText>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">NPI Type</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{evaluation.npiType ? NPI_TYPES[parseInt(evaluation.npiType, 10) - 1]?.label : 'All'}</Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={4}>
            <Typography variant="cardLabel">Outlier Rates</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{parseInt(evaluation.outlier, 10) ? 'Include' : 'Exclude'}</Typography>
          </Grid>
        </Grid>
      </>
    ),
    size: { xs: 12, sm: 10, md: 6, lg: 4 },
  },
  comparisonCard,
  {
    header: 'Providers',
    body1: (renderProvidersInfo().map((p) => (
      <Typography fontSize="0.875rem">{p}</Typography>
    ))),
    size: { xs: 12, sm: 10, md: 6, lg: 4 },
    actions: (
      <UtilityButton color="secondary" size="small" startIcon={<AddBoxOutlinedIcon />} onClick={() => setNpiDetailOpen(true)} className="HtmlToImageIgnore">
        NPI Linkage Detail
      </UtilityButton>),
  },
  ];

  const evaluationInfoForPopulationCards = [{
    header: 'Study Criteria',
    body1: (
      <Grid item container>
        <Grid item xs={4}>
          <Typography variant="cardLabel">Census Summary</Typography>
        </Grid>
        <Grid item xs={8}>
          <BreakWordText variant="body1">{evaluation.census?.name}</BreakWordText>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="cardLabel">Total MSAs</Typography>
        </Grid>
        <Grid item xs={8}>
          <BreakWordText variant="body1">{censusStats?.noOfMSA}</BreakWordText>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="cardLabel">Total Population</Typography>
        </Grid>
        <Grid item xs={8}>
          <BreakWordText variant="body1">{censusStats?.totalPopulation.toLocaleString('en-US')}</BreakWordText>
        </Grid>
      </Grid>
    ),
    size: { xs: 12, sm: 10, md: 6, lg: 4 },
  },
  comparisonCard,
  ];

  const inputInfoCardsMap = {
    [EVALTYPE.PROVIDER]: evaluationInfoForProviderCards,
    [EVALTYPE.MARKET]: evaluationInfoForMarketCards,
    [EVALTYPE.POPULATION]: evaluationInfoForPopulationCards,
  };

  const evaluationInfoForCards = inputInfoCardsMap[evaluation.evalType];

  const handleNpiClose = () => {
    setNpiDetailOpen(false);
  };

  const handleCloneClick = () => {
    store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.cloneStudy[evaluation.evalType]));

    const stateParam = {
      state: {
        isCLone: true,
      },
    };
    navigate(EVALTYPE_ROUTE_MAP[evaluation.evalType], stateParam);
  };

  const handleExportCSV = (request) => {
    const exportCsvTrackText = newStudy || evaluation.evalType === EVALTYPE.POPULATION
      ? Constants.TRACK_VISIT_TYPE.exportNewCsv[evaluation.evalType]
      : Constants.TRACK_VISIT_TYPE.exportOldCsv[evaluation.evalType];
    store.dispatch(trackVisit(exportCsvTrackText));

    store.dispatch(exportCSVFileAsZIP(request))
      .unwrap()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        const fileNameWithExt = request.zipFileName + ZIP_FILE_EXTENSION;
        link.href = url;
        link.download = fileNameWithExt;
        link.click();
        window.URL.revokeObjectURL(url);
      });
  };

  const handleRerunClick = () => {
    store.dispatch(trackVisit(Constants.TRACK_VISIT_TYPE.rerunStudy[evaluation.evalType]));
    setShowRerunWarning(true);
  };

  const confirmRerun = () => {
    const compareNetworks = evaluation.compareNetworks.map((nw) =>
      ({ networkCode: nw.networkCode }));
    const npiValues = evaluation.npis.map((record) =>
      ({ npi: record.npi, npiLabel: record.npiLabel }));
    store.dispatch(
      save({
        ...evaluation,
        compareNetworks,
        npis: npiValues,
      }),
    )
      .unwrap().then(() => {
        store.dispatch(setPrevVisitState(null));
        navigate(RoutePath.app.evaluation.path);
      });
  };

  // disable these message for Sep26 off-cycle scheduled release
  // const renderStudyExpireMsg = () => {
  //   if (evaluation.evalType === EVALTYPE.PROVIDER) {
  //     return (
  //       <Typography color="red">
  //         This study will be read-only soon to make room for a new and improved Provider
  //         Study design. Click Rerun prior to 2024-10-24 to refresh the results of this study.
  //       </Typography>
  //     );
  //   } if (evaluation.evalType === EVALTYPE.MARKET) {
  //     return (
  //       <Typography color="red">
  //         This study will be read-only soon to make room for a new and improved Market
  //         Study design. Click Rerun prior to 2024-11-21 to refresh the results of this study.
  //       </Typography>
  //     );
  //   }
  //   return null;
  // };

  const cancelRerun = () => {
    setShowRerunWarning(false);
  };

  function DisplayRerun() {
    if ((evaluation.evalType !== EVALTYPE.PROVIDER && evaluation.evalType !== EVALTYPE.MARKET)
      || newStudy) {
      return (
        <UtilityButton
          color="secondary"
          startIcon={<RefreshIcon />}
          onClick={() => handleRerunClick()}>
          Rerun
        </UtilityButton>
      );
    }
    return (
      <UtilityButton
        disabled
        sx={{ '&:hover': { backgroundColor: 'transparent' }, '&.Mui-disabled': { color: ALERT_TEXT_COLOR } }}
        startIcon={<BookIcon />}>
        Read Only
      </UtilityButton>
    );
  }
  const theme = useTheme();
  const systemDate = useSelector((state) => state.systemDate.systemDate) || [];
  const selectedTabValue = evaluation.evalType === EVALTYPE.POPULATION ? '' : `${selectedTab}_`;
  // to truncate study name, if it's length is more than 150 characters
  const truncatedStudyName = evaluation?.name?.length > 150 ? `${evaluation.name.slice(0, 150)}...` : evaluation?.name;
  const fileName = `${truncatedStudyName}_${selectedTabValue}${systemDate[0]}-${padStartToNumber(systemDate[1])}-${padStartToNumber(systemDate[2])}`;
  const zipFileName = `${truncatedStudyName.replace(',', '_')}_${systemDate[0]}-${padStartToNumber(systemDate[1])}-${padStartToNumber(systemDate[2])}`;

  return (
    <>
      <Grid item xs={9}>
        <Typography variant="h4">
          {header}
        </Typography>
      </Grid>
      <Grid xs={3} item justifyContent="flex-end" className="HtmlToImageIgnore">
        <Stack direction="row" spacing={1}>
          <UtilityButton
            id="export-img-button"
            color="secondary"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={() => handleExport(
              studyRef,
              fileName,
              theme.palette.background.default,
              Constants.TRACK_VISIT_TYPE.exportImage[evaluation.evalType],
            )}>
            Export Image
          </UtilityButton>
          <UtilityButton
            id="export-csv-button"
            color="secondary"
            startIcon={<img alt="CSV" src={csvExportIcon} />}
            onClick={() => handleExportCSV({
              evaluationId: evaluation.id,
              studyType: evaluation.evalType,
              zipFileName,
            })}>
            Export CSV
          </UtilityButton>
          <DisplayRerun />
          <UtilityButton
            color="secondary"
            startIcon={<ContentCopyIcon />}
            onClick={() => handleCloneClick()}>
            Clone
          </UtilityButton>
        </Stack>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 'unset' }}>
        <Stack direction="row" spacing={0.25}>
          <Grid item container xs={6}>
            <Typography sx={{ fontSize: '0.875rem' }}>
              Created on
              {convertDatetime(evaluation.createdAt)}
              / Last Update on
              {convertDatetime(evaluation.updatedAt)}
            </Typography>
            <AccessTimeIcon
              color="secondary"
              sx={{
                height: '1.25rem',
                width: '1.25rem',
              }}
            />
            {SHOW_PANORAMA_VERSION
              && (
              <Typography sx={{ fontSize: '0.875rem' }}>
                &nbsp;
                {Constants.PANORAMA_VERSION_MAP[evaluation.panoramaVersion]}
              </Typography>
              )}
          </Grid>
        </Stack>
      </Grid>
      <Grid item container spacing={3} columns={12}>
        {
          evaluationInfoForCards.map((evaluationInfoForCard) => (
            <Grid item {...evaluationInfoForCard.size} key={evaluationInfoForCard.header}>
              <BasicCard content={evaluationInfoForCard} />
            </Grid>
          ))
        }
      </Grid>
      <EvaluationNpiLinkageDetailDialog
        open={npiDetailOpen}
        handleClose={handleNpiClose}
      />
      <NavigateAwayWarningDialog
        open={showRerunWarning}
        onConfirm={confirmRerun}
        onCancel={cancelRerun}
        content={{ title: 'Are you sure you want to rerun the study?', body: '', confirm: 'Yes, Rerun', cancel: 'Cancel' }}
      />
    </>
  );
}

export default memo(EvaluationBasicInfo);

import React, { useEffect } from 'react';
import MultiCategoryMultiSelect from 'common/components/MultiCategoryMultiSelect';
import useKeywordSuggestions from '../hooks/useKeywordSuggestions';

const categories = {
  CODE: {
    All: '',
    'CPT\u00AE': 'CPT',
    'MS-DRG': 'MS-DRG',
    HCPCS: 'HCPCS',
    RC: 'RC',
    'Code-Cat': 'Code Category',
  },
  PROVIDER: {
    All: '',
    TIN: 'TIN',
    NPI: 'NPI',
    Grouping: 'Provider Group',
    Taxonomy: 'Provider Taxonomy',
  },
};

const type = {
  CODE: 'Code',
  PROVIDER: 'Provider',
};

const fieldName = {
  CODE: 'selectedCodes',
  PROVIDER: 'selectedProviders',
};

const autocompId = {
  CODE: 'codeFilter',
  PROVIDER: 'providerFilter',
};
const getOptionText = (option) => (
  <span>
    <b>{option.type}</b>
    {` - ${option.value} ${option.description}`}
  </span>
);

const getChipLabel = (option) => {
  let chipLabel = `${option.type} ${option.value}`;
  if (option.type === categories.CODE['Code-Cat']
    || option.type === categories.PROVIDER.Taxonomy
    || option.type === categories.PROVIDER.Grouping) {
    chipLabel = `${option.value}`;
  }
  return chipLabel;
};

export default function ProviderOrCodeSelect(props) {
  const {
    variant,
    stateMsaOption,
    clearCount,
    selectedStateObj,
    selectedMsaObj,
  } = props;

  const {
    suggestionsLoading,
    suggestions,
    setKeyword,
    setStateAndMsaNo,
    setType,
  } = useKeywordSuggestions(variant);

  useEffect(() => {
    setKeyword('');
    if (stateMsaOption === 'state') setStateAndMsaNo(selectedStateObj?.code, null);
    else setStateAndMsaNo(null, selectedMsaObj?.msaNo);
  }, [stateMsaOption, selectedStateObj, selectedMsaObj]);

  useEffect(() => {
    if (clearCount) {
      setKeyword('');
    }
  }, [clearCount]);

  return (
    <MultiCategoryMultiSelect
      categories={Object.keys(categories[variant])}
      label={`${type[variant]} Selection`}
      infoText={`Use ${type[variant]} type to narrow typeahead results`}
      fieldName={fieldName[variant]}
      autocompId={autocompId[variant]}
      autocompProps={{
        filterOptions: (options) => options,
      }}
      handleCategoryChange={(category) => setType(categories[variant][category])}
      options={suggestions}
      optionsLoading={suggestionsLoading}
      returnOptionText={(option) => getOptionText(option)}
      onInputChange={(e) => setKeyword(e.target.value)}
      returnChipTooltip={(option) => (`${option.value} ${option.description}`)}
      returnChipLabel={(option) => getChipLabel(option)}
      forceCategoryReset={clearCount}
    />
  );
}

import React from 'react';
import RoutePath from 'app/router/RoutePath';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { List } from '@mui/material';
import { usePermissions } from 'features/common/hooks/usePermissions';
import { PERMISSION } from 'common/Constants';
import { SHOW_UTILIZATION_STUDY } from 'common/AppConstant';
import SubMenuItem from './SubMenuItem';

const subMenuMapWithPermision = (hasPermission) => ({
  completed: {
    title: 'Completed Studies',
    path: RoutePath.app.evaluation.path,
    itemCount: 0,
    show: true, // should the menu be shown on UI?
  },
  market: {
    title: 'Create Market Study',
    path: RoutePath.app.evaluation.subpage.createMarketStudy.path,
    show: true,
  },
  population: {
    title: 'Create Population Study',
    path: RoutePath.app.evaluation.subpage.createPopulationStudy.path,
    show: hasPermission(PERMISSION.POPULATION_STUDY),
  },
  provider: {
    title: 'Create Provider Study',
    path: RoutePath.app.evaluation.subpage.createProviderStudy.path,
    show: true,
  },
  utilization: {
    title: 'Create Utilization Study',
    path: RoutePath.app.evaluation.subpage.createUtilizationStudy.path,
    show: hasPermission(PERMISSION.UTILIZATION_STUDY) && SHOW_UTILIZATION_STUDY,
  },
  census: {
    title: 'Census Summary Files',
    path: RoutePath.app.evaluation.subpage.censusFiles.path,
    show: hasPermission(PERMISSION.POPULATION_STUDY),
  },
  claims: {
    title: 'Claims Summary Files',
    path: RoutePath.app.evaluation.subpage.claimsFiles.path,
    show: hasPermission(PERMISSION.UTILIZATION_STUDY) && SHOW_UTILIZATION_STUDY,
  },
});

export default function IntelligenceSubMenu({ sideBarOpen }) {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const evaluationCount = useSelector((state) => state.count.completedEvaluationCount);
  const subMenuMap = subMenuMapWithPermision(hasPermission);
  subMenuMap.completed.itemCount = evaluationCount;
  return (
    <List component="div" disablePadding>
      {Object.keys(subMenuMap).map((subMenu) => {
        if (subMenuMap[subMenu].show) {
          return (
            <SubMenuItem
              key={subMenu}
              title={subMenuMap[subMenu].title}
              clickHandler={() => navigate(subMenuMap[subMenu].path)}
              sideBarOpen={sideBarOpen}
              itemCount={subMenuMap[subMenu].itemCount}
            />
          );
        }
        return null;
      })}
    </List>
  );
}

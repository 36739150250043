import {
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';

export default function CreateUtilizationStudy() {
  return (
    <Grid container>
      <Grid container item spacing={2} marginLeft={-0.5} xs={12} md={9} lg={7} xl={6}>
        <Grid item xs={12} sx={{ paddingTop: '0px' }}>
          <Typography variant="h1">
            Create Utilization Study
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getAllReleaseNotesPDFLinks, getReleaseNote } from 'features/releaseNotes/state/service';
import { PDF_FILE_EXTENSION } from 'common/AppConstant';
import { Link, List, ListItem, useTheme, Typography, Stack } from '@mui/material';
import CircleIconIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';

const getFileName = (filePath) => {
  const filename = filePath?.split('\\').pop().split('/').pop();
  return filename.split('.').slice(0, -1).join('.');
};

export default function ReleaseNotes() {
  const [pdfLinks, setPdfLinks] = useState([]);
  const theme = useTheme();

  const CustomMarker = styled(CircleIconIcon)(() => ({
    color: theme.palette.secondary.link,
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  }));

  const handleClick = (filePath) => {
    try {
      getReleaseNote(filePath).then((data) => {
        const url = window.URL.createObjectURL(data);
        window.open(url, '_blank');
      });
    } catch (error) {
      // console.error('Error getting release note:', error);
    }
  };
  useEffect(() => {
    try {
      getAllReleaseNotesPDFLinks().then((data) => {
        setPdfLinks(data);
      });
    } catch (error) {
      setPdfLinks(null);
    }
  }, []);
  return (
    <Stack>
      <Typography variant="h4" mb={3} color={theme.palette.secondary.link}>
        Release Notes
      </Typography>
      <Typography variant="body1" color={theme.palette.accent.gray600}>
        Learn more about what&apos;s included in our releases.
      </Typography>
      <List>
        {pdfLinks.map((filePath) => (
          <ListItem key={filePath}>
            <Link id="pdf-file-link" onClick={() => handleClick(filePath)} target="_blank" rel="noreferrer" sx={{ color: theme.palette.secondary.link }}>
              <CustomMarker />
              {getFileName(filePath)}
              <span style={{ textDecoration: 'none', display: 'inline-block', whiteSpace: 'pre' }}>{` (${PDF_FILE_EXTENSION})`}</span>
              <OpenInNewIcon fontSize="small" style={{ translate: '3px 3px' }} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}

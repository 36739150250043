/*
useFormContext() hook errors out if the form is not wrapped by FormProvider. Hence,
formControl prop is mandatory to be passed if and only if the form is not wrapped under FormProvider
formControl can be skipped otherwise.
*/

import React from 'react';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export default function FormAutocompleteTextField(props) {
  const {
    formControl,
    fieldName,
    onValueChange,
    autocompleteProps,
    textFieldProps,
    tooltipText,
    customEndAdornment,
    fieldRules,
    isError,
  } = props;
  let methods;
  if (!formControl) methods = useFormContext();
  return (
    <Controller
      name={fieldName}
      control={formControl || methods?.control}
      rules={fieldRules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          fullWidth
          onChange={(_e, data) => {
            field.onChange(data);
            if (typeof onValueChange === 'function') onValueChange(data, field);
          }}
          {...autocompleteProps}
          renderInput={(inProps) => (
            <TextField
              {...inProps}
              error={isError}
              InputLabelProps={{
                ...inProps.InputLabelProps,
                shrink: true,
              }}
              InputProps={{
                ...inProps.InputProps,
                endAdornment: (
                  <>
                    {inProps.InputProps.endAdornment}
                    {customEndAdornment}
                  </>
                ),
              }}
              {...textFieldProps}
              label={
                (
                  <Tooltip title={tooltipText} placement="top">
                    <span>{textFieldProps.label}</span>
                  </Tooltip>
                )
              }
            />
          )}
        />
      )}
    />
  );
}

import React from 'react';
import { Typography, TableSortLabel, TableCell, Tooltip } from '@mui/material';

export default function TableCellHeader({
  key,
  label,
  sortDirection,
  active,
  direction,
  onClick,
  IconComponent,
  headerColor,
  headerAlign,
  headerSX,
  sx,
  tableCellSX,
  variant,
  toolTipTitle = '',
}) {
  return (
    <TableCell
      key={key}
      sortDirection={sortDirection}
      align="left"
      padding="none"
      sx={{ ...tableCellSX, borderBottom: 'none' }}
    >
      <TableSortLabel
        active={active}
        direction={direction}
        onClick={onClick}
        sx={sx}
        IconComponent={IconComponent}
      >
        <Tooltip key={key} title={toolTipTitle} placement="top">
          <Typography
            variant={variant}
            color={headerColor}
            align={headerAlign}
            sx={headerSX}
          >
            {label}
          </Typography>
        </Tooltip>
      </TableSortLabel>
    </TableCell>
  );
}

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Box,
  IconButton, Typography,
} from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import Close from '@mui/icons-material/Close';

export default function LogoutConfirmDialog({ onClose, onLogOut }) {
  return (
    <Dialog id="signout-popup" open="true" onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h5">
          <WarningIcon sx={{ transform: 'translate(-1px, 5px)' }} />
          You will be signed out.
        </Typography>
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Grid container flexDirection="row" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Are you sure you want to sign-out?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel-button"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          id="confirm-button"
          color="primary"
          variant="contained"
          onClick={() => {
            onLogOut();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default {
  login: {
    path: '/login',
  },
  sso: {
    path: '/sso',
  },
  ssoLogin: {
    path: '/sso-login',
  },
  portal: {
    path: '/portal',
  },
  app: {
    path: '/app',
    search: {
      subpath: 'search',
      path: '/app/search',
    },
    admin: {
      subpath: 'admin',
      path: '/app/admin',
      networkMapping: {
        subpath: 'network-mapping',
        path: '/app/admin/network-mapping',
      },
      user: {
        subpath: 'user',
        path: '/app/admin/user',
      },
    },
    evaluation: {
      subpath: 'intelligence',
      path: '/app/intelligence',
      subpage: {
        detail: {
          subpath: 'detail',
          path: '/app/intelligence/detail',
        },
        createMarketStudy: {
          subpath: 'create-market-study',
          path: '/app/intelligence/create-market-study',
        },
        createProviderStudy: {
          subpath: 'create-provider-study',
          path: '/app/intelligence/create-provider-study',
        },
        createUtilizationStudy: {
          subpath: 'create-utilization-study',
          path: '/app/intelligence/create-utilization-study',
        },
        createPopulationStudy: {
          subpath: 'create-population-study',
          path: '/app/intelligence/create-population-study',
        },
        censusFiles: {
          subpath: 'census-files',
          path: '/app/intelligence/census-files',
        },
        claimsFiles: {
          subpath: 'claims-files',
          path: '/app/intelligence/claims-files',
        },
      },
    },
    export: {
      subpath: 'export',
      path: '/app/export',
    },
    download: {
      subpath: 'download',
      path: '/app/download',
    },
    weights: {
      subpath: 'weights',
      path: '/app/weights',
    },
    releaseNotes: {
      subpath: 'release-notes',
      path: '/app/release-notes',
    },
    home: {
      subpath: 'home',
      path: '/app/home',
    },
    error: {
      subpath: 'error/denied',
      path: '/app/error/denied',
    },
    resources: {
      subpath: 'resources',
      path: '/app/resources',
      subpage: {
        releaseNotes: {
          subpath: 'release-notes',
          path: '/app/resources/release-notes',
        },
      },
    },
  },
};

import React, { useRef, useState } from 'react';
import { FORMAT_NATURAL, FORMAT_RATE } from 'common/util/commonUtil';
import { getFileContent } from 'common/service';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import { Typography } from '@mui/material';

export default function ClaimsDetail({ data }) {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const initialRowID = {
    nextID: 0,
    prevID: { 0: 0 },
  };
  const rowID = useRef(initialRowID);
  const [result, setResult] = useState({
    data: [],
    page: 0,
    totalCount: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const tableRef = useRef();

  const columns = [
    {
      title: 'Rendering NPI',
      field: 'renderingNpi',
      sorting: false,
    },
    {
      title: 'Billing Class',
      field: 'billingClass',
      sorting: false,
    },
    {
      title: 'Billing Code Type',
      field: 'billingCodeType',
      sorting: false,
    },
    {
      title: 'Billing Code',
      field: 'billingCode',
      sorting: false,
    },
    {
      title: 'Sum Allowed Amount',
      field: 'sumAllowedAmount',
      render: (rd) => FORMAT_RATE(Number(rd.sumAllowedAmount)),
      align: 'right',
      sorting: false,
    },
    {
      title: 'Count Unique Claim IDs',
      field: 'countUniqueClaimIDs',
      render: (rd) => FORMAT_NATURAL(Number(rd.countUniqueClaimIDs)),
      align: 'right',
      sorting: false,
    },
    {
      title: 'Sum Service Units',
      field: 'sumServiceUnits',
      render: (rd) => FORMAT_NATURAL(Number(rd.sumServiceUnits)),
      align: 'right',
      sorting: false,
    },
  ];

  return (
    <CustomizedMaterialTable
      title={<Typography variant="h6" style={{ maxWidth: '500px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{data?.name}</Typography>}
      tableRef={tableRef}
      options={{
        pageSize: 20,
        sorting: false,
      }}
      columns={columns}
      localization={{
        toolbar: { searchPlaceholder: 'Filter', searchTooltip: 'Filter by Billing Code' },
      }}
      state={{
        isLoading: isLoadingData,
      }}
      data={(query) => getFileContent(
        query,
        data.claimsSummaryFilePath,
        setIsLoadingData,
        rowID,
        currentPage,
        setCurrentPage,
        initialRowID,
        result,
        setResult,
      )}
    />
  );
}
